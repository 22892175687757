<template>
  <v-main>

    <v-container
        id="dependent-container"
        fluid
        tag="section"
    >

        <base-material-card
            icon="mdi-family-tree"
            class="px-5 py-3"
            ref="form"
        >

          <v-card-text v-if="errors.length">
            <v-alert color="error"
                     outlined
                     dark
                     :key="error.id"
                     show variant="danger"
                     v-for="error in errors">
              {{ error }}
            </v-alert>
          </v-card-text>

          <v-form ref="dependentForm"
                  v-model="valid"
                  @submit.prevent="validate"
                  lazy-validation
          >

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    v-model="dependent.relationType"
                    label="Relationship"
                    :items="relationTypes"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.firstName"
                    label="First Name"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.middleName"
                    label="Middle Name/Initial (optional)"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.lastName"
                    label="Last Name"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.city"
                    label="City/Town"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.county"
                    label="County"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    label="Country"
                    v-model="computedCountry"
                    :items="countriesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="dependent.birthYear"
                    label="Year of Birth (YYYY)"
                    :rules="rules.birthYear"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-radio-group
                    v-model="dependent.gender"
                    mandatory
                    row
                >
                  <v-radio
                      v-for="(item, i) in genderOptions"
                      :key="i"
                      :label="item"
                      :value="item"
                  >
                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="6"
                  sm="4"
              >
                <v-select
                    v-model="updateReason"
                    label="Reason for Change"
                    :items="updateReasonOptions"
                    :rules="rules.required"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
                  v-if="updateReason === 'Other'"
              >
                <v-text-field
                    v-model="otherUpdateReason"
                    label="Other Reason for Change"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
  import api from '@/services/api'
  import {mapGetters} from 'vuex'
  import validation from "@/utils/validation"
  import geoUtils from "@/utils/geography"
  import depUtils from "@/utils/dependents"

  export default {
    name: 'CoreDependentView',

    async created() {
      this.userId = this.getUserId
      this.dependentId = this.$route.params.id
      this.statesList = geoUtils.fetchUsStates()
      this.countriesList = geoUtils.fetchCountries()
      if (this.dependentId) {
        await this.fetchDependent()
      }
      await this.fetchUser()
      await this.fetchDependents()
      await this.fetchRelationTypes()
    },

    computed: {
      ...mapGetters({
        getUserId: 'getUserId'
      }),
      computedCountry: {
        get() {
          return geoUtils.findCountryByCode(this.dependent.country)
        },
        set(value) {
          this.dependent.country = value.code
        }
      }
    },

    watch: {
      dependent: function() {
        this.fetchRelationTypes()
      }
    },

    methods: {
      validate() {
        if(this.$refs.dependentForm.validate()) {
          if (this.dependent.id !== undefined) {
            return this.updateDependent()
          }
          this.addDependent()
        }
      },
      buildRelationTypeOptions() {
        let counts = depUtils.countDependentsByRelation(this.dependents)
        if (this.dependent.relationType !== undefined && this.dependent.relationType.id !== undefined) {
          counts[this.dependent.relationType.id] -= 1
        }
        this.relationTypes = this.relationTypes.filter(el => {
          return (el.max == null) || (counts[el.id] === undefined) || (counts[el.id] < el.max)
        })
      },
      fetchRelationTypes() {
        api
            .fetchRelationTypes()
            .then(response => {
              this.relationTypes = response.data
              this.buildRelationTypeOptions()
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      fetchUser() {
        api
            .fetchUser(this.userId)
            .then(response => {
              this.user = response.data
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      fetchDependent() {
        api
            .fetchDependent(this.userId, this.dependentId)
            .then(response => {
              this.dependent = response.data
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      fetchDependents() {
        api
            .fetchDependents(this.userId)
            .then(response => {
              this.dependents = response.data
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      addDependent() {
        this.$gtag.event('add_dependent')

        let payload = this.dependent
        payload.comment = this.otherUpdateReason || this.updateReason
        payload.relationTypeId = payload.relationType.id
        api
            .addDependent(this.userId, payload)
            .then(response => {
              if (response.status === 201) {
                this.$router.push(`/dependents/view`)
              }
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      updateDependent() {
        this.$gtag.event('update_dependent')

        let payload = this.dependent
        payload.relationTypeId = payload.relationType.id
        payload.comment = this.otherUpdateReason || this.updateReason
        api
            .updateDependent(this.userId, this.dependentId, payload)
            .then(response => {
              if (response.status === 200) {
                this.$router.push('/dependents/view')
              }
            })
            .catch(err => {
              this.handleError(err)
            })
      },
      handleError(err) {
        let errorMessage = api.parseResponseError(err)
        this.errors.push(errorMessage)
      }
    },

    data() {
      return {
        valid: true,
        errors: [],
        userId: null,
        dependentId: null,
        user: {},
        dependent: {
          country: 'KE'
        },
        genderOptions: [
          'Male',
          'Female',
          'Other'
        ],
        relationTypes: [],
        countriesList: [],
        updateReason: null,
        otherUpdateReason: null,
        updateReasonOptions: [
          'Marriage',
          'Birth or Legal Adoption',
          'Divorce',
          'Death',
          'Other'
        ],
        dependents: [],
        dependentCounts: {},
        rules: {
          required: [
            v => !!v || 'required'
          ],
          numeric: [
            v => validation.isInteger(v) || 'must be a number'
          ],
          birthYear: [
            v => validation.isInteger(v) || 'must be a year number',
            v => validation.isMoreThan(v, 1900) || 'must be after 1900',
            v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
          ]
        }
      }
    }
  }
</script>
