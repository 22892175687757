export default {
    countDependentsByRelation(dependents) {
        const result = {}
        dependents.forEach(dep => {
            const typeId = dep.relationType.id
            if (result[typeId] !== undefined) {
                result[typeId] += 1
            } else {
                result[typeId] = 1
            }
        })

        return result
    }
}